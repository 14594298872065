import {type Dispatch, type RefObject, type SetStateAction} from 'react';

import {type Behaviour} from '@onroadvantage/onroadvantage-api';
import {type FormikProps} from 'formik';

import {type LookupTablesResult} from '../hooks/useGenerateLookupTables';
import {type WebSocketHook} from '../WebsocketTypes';

export interface EventReviewContextType {
  webSocket: WebSocketHook;
  formRef: RefObject<FormikProps<Record<string, boolean>>>;
  keyBuffer: string;
  setKeyBuffer: Dispatch<SetStateAction<string>>;
  snapshotList: number[];
  setSnapshotList: Dispatch<SetStateAction<number[]>>;
  onEventSelectedShortCuts: string[];
  setOnEventSelectedShortcuts: Dispatch<SetStateAction<string[]>>;
  showOnBreakModal: boolean;
  setShowOnBreakModal: Dispatch<SetStateAction<boolean>>;
  behaviours?: Behaviour[];
  notificationComments: Array<{
    text: string;
    commentTime: Date;
    [key: symbol]: number;
  }>;
  setNotificationComments: Dispatch<
    SetStateAction<
      Array<{
        text: string;
        commentTime: Date;
        [key: symbol]: number;
      }>
    >
  >;
  handleFaultyClip: () => void;
  handleNoIssue: () => void;
  handleLateClip: () => void;
  resetContext: () => void;
  lookupTableData: LookupTablesResult;
}

export const useEventReviewContextResponseInitial: EventReviewContextType = {
  webSocket: {
    connect: () => {},
    connected: false,
    disconnect: () => {},
    events: [],
    sendReview: () => {},
    queueEmpty: false,
    eventCount: null,
    activeEventType: null,
  },
  formRef: {
    current: null,
  },
  keyBuffer: '',
  setKeyBuffer: () => {},
  snapshotList: [],
  setSnapshotList: () => {},
  onEventSelectedShortCuts: [],
  setOnEventSelectedShortcuts: () => {},
  showOnBreakModal: false,
  setShowOnBreakModal: () => {},
  behaviours: [],
  notificationComments: [],
  setNotificationComments: () => {},
  handleFaultyClip: () => {},
  handleNoIssue: () => {},
  handleLateClip: () => {},
  resetContext: () => {},
  lookupTableData: {
    combinedLookup: {},
    categorizedBehaviorsWithShortcuts: [],
    categorizedObservationsWithShortcuts: [],
    behaviorLookupTable: {},
    observationLookupTable: {},
  },
};
