import {useState, useEffect, useCallback, useRef} from 'react';

import {useAuthStore} from '../../common/stores/authStore';
import {
  type ActiveEventType,
  type LytxPreEventData,
  type Send,
  type SurfSightEventData,
  type WebSocketEvents,
} from '../WebsocketTypes';
import {useRedirectToBaseIfNotConnected} from './useRedirectToBaseIfNotConnected';
import {config} from '../../../config';
import {
  isActiveLytxEvent,
  isActiveSurfSightEvent,
} from '../utils/eventDistinctions';
import {handleWebSocketMessage} from '../utils/eventReviewWebsocketMessageHandler';

export const useEventReviewWebSocket = () => {
  const [connected, setConnected] = useState<boolean>(false);
  const [connecting, setConnecting] = useState<boolean>(false);
  const [events, setEvents] = useState<
    Array<WebSocketEvents | SurfSightEventData | LytxPreEventData>
  >([]);
  const [queueEmpty, setQueueEmpty] = useState<boolean>(false);
  const [eventCount, setEventCount] = useState<number | null>(null);
  const [activeEventType, setActiveEventType] =
    useState<ActiveEventType | null>(null);
  const wsRef = useRef<WebSocket | null>(null);
  const ssoToken = useAuthStore((state) => state.ssoToken);

  // Listen to connection to see if we need to redirect the user back to the base route
  useRedirectToBaseIfNotConnected({connected, connecting});

  const connect = useCallback(() => {
    setConnecting(true);
    if (wsRef?.current != null && wsRef.current.OPEN === true) {
      wsRef.current.close();
    }
    wsRef.current = new WebSocket(config.earlyWarningWebSocketUrl);
    wsRef.current.onopen = () => {
      wsRef.current?.send(JSON.stringify({type: 'auth', token: ssoToken}));
      wsRef.current?.send(JSON.stringify({type: 'event_count'}));
      setConnected(true);
      setConnecting(false);
    };

    wsRef.current.onmessage = (event: MessageEvent<string>) => {
      const data: unknown = JSON.parse(event.data);
      handleWebSocketMessage({
        data,
        wsRef,
        setEvents,
        setQueueEmpty,
        setEventCount,
        queueEmpty,
        events,
        setActiveEventType,
      });
    };

    wsRef.current.onclose = () => {
      setConnected(false);
    };
  }, [ssoToken, queueEmpty, events]);

  const disconnect = useCallback(() => {
    if (wsRef.current != null) {
      wsRef.current.close();
    }
  }, []);

  const sendReview = useCallback(
    (message: Send) => {
      if (wsRef.current == null || events.length === 0) {
        return;
      }

      wsRef.current.send(JSON.stringify(message));

      setEvents((prevEvents) => {
        if (prevEvents.length === 0) {
          return [];
        }
        const pendingEvents = prevEvents.slice(1);

        if (pendingEvents?.length > 0) {
          pendingEvents[0].reviewStartTime = new Date();
          if (isActiveSurfSightEvent(pendingEvents[0])) {
            setActiveEventType('SurfSight');
          } else if (isActiveLytxEvent(pendingEvents[0])) {
            setActiveEventType('Lytx');
          } else {
            setActiveEventType('LytxPreEvent');
          }
          return pendingEvents;
        }
        return [];
      });
    },
    [events],
  );

  useEffect(() => {
    return () => {
      disconnect();
    };
  }, [disconnect]);

  return {
    connected,
    events,
    setEvents,
    sendReview,
    connect,
    disconnect,
    queueEmpty,
    eventCount,
    activeEventType,
  };
};
