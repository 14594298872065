import {useEffect, useMemo, useState} from 'react';
import {toast} from 'react-toastify';

import {
  type OrganisationGroupExternal,
  type BehaviourOutcomeCalculate,
  type HindsightStandardConfig,
  type EarlyWarningEventDetailsResponse,
} from '@onroadvantage/onroadvantage-api';

import {
  earlyWarningEventApi,
  hindsightConfigApi,
  organisationGroupExternalApi,
} from '../../../api';
import {useEventReviewContext} from '../contexts/EventReviewContext';

export interface EventDetailsData {
  group: OrganisationGroupExternal;
}

export const useEventFeedback = () => {
  const {webSocket} = useEventReviewContext();
  const [eventDetailsData, setEventDetailsData] = useState<EventDetailsData>();
  const [inlineEventData, setInlineEventData] =
    useState<EarlyWarningEventDetailsResponse>();
  const [hindsightConfigData, setHindsightConfigData] =
    useState<HindsightStandardConfig[]>();
  const {groupId, vehicleId, triggerId} = useMemo(() => {
    const eventData = webSocket.events[0]?.data?.data;
    return {
      groupId: eventData?.groupId,
      vehicleId: eventData?.vehicleId,
      triggerId: eventData?.eventTriggerId,
    };
  }, [webSocket]);

  useEffect(() => {
    const fetchEventDetails = async () => {
      try {
        const eventDetails =
          await earlyWarningEventApi.apiEarlyWarningEventDetailsGet({
            eventTriggerId: triggerId,
            vehicleId,
            groupId,
            telematicsProviderName: 'Lytx',
          });

        if (eventDetails != null) {
          setInlineEventData(eventDetails);
        }

        const organisationResponse =
          await organisationGroupExternalApi.apiOrganisationGroupExternalOrganisationGroupExternalIdGet(
            {
              organisationGroupExternalId: groupId,
            },
          );

        setEventDetailsData({
          group: organisationResponse ?? null,
        });
      } catch {
        toast('Error fetching event details:', {type: 'error'});
      }
    };

    if (
      groupId != null &&
      vehicleId != null &&
      triggerId != null &&
      webSocket.activeEventType === 'Lytx'
    ) {
      void fetchEventDetails();
    }
  }, [groupId, vehicleId, triggerId, webSocket.activeEventType]);

  const generateHindsightData = async (
    outcomes?: BehaviourOutcomeCalculate[] | null,
  ) => {
    if (outcomes == null || eventDetailsData?.group?.id == null) {
      setHindsightConfigData([]);
      return;
    }

    const outcomeIds = outcomes
      .map((outcome: BehaviourOutcomeCalculate) => outcome.id)
      .filter((id) => id != null);

    const response =
      await hindsightConfigApi.apiHindsightStandardConfigCalculateGet({
        behaviourOutcomeIds: outcomeIds,
        organisationGroupExternalId: eventDetailsData?.group.id,
      });

    if (response?.items != null) {
      setHindsightConfigData(response.items);
    }
  };

  return {
    inlineEventData,
    eventDetailsData,
    generateHindsightData,
    hindsightConfigData,
  };
};
