import {type MouseEvent, type SyntheticEvent, useCallback} from 'react';

import Stack from '@mui/material/Stack';
import {styled} from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import {useEventReviewContext} from '../../contexts/EventReviewContext';
import {
  isActiveLytxEvent,
  isActiveLytxPreEvent,
  isActiveSurfSightEvent,
} from '../../utils/eventDistinctions';

export function VideoSnapshots() {
  const {webSocket, snapshotList} = useEventReviewContext();

  const getVideoSrc = useCallback(
    (startTime: number, endTime: number) => {
      if (webSocket.events[0] == null) return undefined;

      if (
        isActiveLytxEvent(webSocket.events[0]) ||
        isActiveSurfSightEvent(webSocket.events[0])
      ) {
        return `${webSocket.events[0]?.data?.media?.accessURI}#t=${startTime},${endTime}`;
      }

      if (isActiveLytxPreEvent(webSocket.events[0])) {
        return `${webSocket.events[0]?.data?.data?.signedMp4Uri}#t=${startTime},${endTime}`;
      }

      return undefined;
    },
    [webSocket.events],
  );

  const handleVideoClick = useCallback(
    async (e: MouseEvent<HTMLVideoElement>, startTime: number) => {
      const video = e.target as HTMLVideoElement;
      video.currentTime = startTime;
      await video.play();
    },
    [],
  );

  const handleTimeUpdate = useCallback(
    (e: SyntheticEvent<HTMLVideoElement>, endTime: number) => {
      if (e.currentTarget.currentTime >= endTime) {
        (e.target as HTMLVideoElement).pause();
      }
    },
    [],
  );

  return snapshotList.length > 0 ? (
    <Stack bgcolor="#EFEFEF" borderRadius="16px" p="12px">
      <Typography mb="24px" fontSize="16px" fontWeight="700">
        SNAPSHOTS
      </Typography>
      <Stack
        flexWrap="wrap"
        justifyContent="space-between"
        display="flex"
        flexDirection="row"
        mb="88px"
      >
        {snapshotList?.length > 0 &&
          snapshotList.map((time) => {
            const startTime = Math.max(0, time - 1);
            const endTime = time + 1;
            const videoSrc = getVideoSrc(startTime, endTime);

            return (
              <SingleVideoContainer
                key={time}
                className="border rounded p-2"
                style={{width: '48%'}}
              >
                <VideoOverlay className="play" />
                {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
                <video
                  autoPlay={false}
                  style={{width: '100%', borderRadius: '16px'}}
                  src={videoSrc}
                  onClick={async (e) => handleVideoClick(e, startTime)}
                  onTimeUpdate={(e) => {
                    handleTimeUpdate(e, endTime);
                  }}
                >
                  Your browser does not support the video tag.
                </video>
              </SingleVideoContainer>
            );
          })}
      </Stack>
    </Stack>
  ) : null;
}

const SingleVideoContainer = styled(Stack)`
  cursor: pointer;
  position: relative;
  margin-bottom: 12px;
  & .play {
    display: none;
  }

  &:hover {
    & .play {
      display: block;
    }
  }
`;

const VideoOverlay = styled('div')`
  width: 100%;
  background-color: white;
  position: absolute;
  height: 100%;
  border-radius: 16px;
  opacity: 0.4;
`;
