import CameraAlt from '@mui/icons-material/CameraAlt';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import {type EarlyWarningEventDetailsResponse} from '@onroadvantage/onroadvantage-api';

import {
  type EventDetailMimicData,
  EventDetailsMimic,
} from './EventDetailsMimic';
import {EventDetailsShimmer} from './shimmers/EventDetailsShimmer';
import {useEventReviewContext} from '../../contexts/EventReviewContext';
import {useEventDetails} from '../../hooks/useEventDetails';
import {type EventDetailsData} from '../../hooks/useEventFeedback';
import {formatLeadTime} from '../../utils/feedbackUtils';

export function EventDetailsWithHook({
  eventDetailsData,
}: {
  eventDetailsData?: EarlyWarningEventDetailsResponse;
}) {
  const {webSocket} = useEventReviewContext();
  const {activeEventType} = webSocket;
  const {
    event,
    serial,
    speed,
    creationDate,
    recordedDate,
    driver,
    leadTime,
    organisation,
    vehicle,
    eventTrigger,
  } = useEventDetails();

  const isLeadTimeExceedingThirtyMinutes =
    (leadTime?.minutes != null && leadTime?.minutes > 30) ||
    (leadTime?.hours ?? 0) > 0;

  return (
    <Stack borderRadius="16px" bgcolor="white" mt="18px" width="100%">
      <Stack
        width="100%"
        bgcolor="#EFEFEF"
        display="flex"
        direction="row"
        alignItems="center"
        columnGap="20px"
        p="8px 20px"
        sx={{
          borderTopLeftRadius: '16px',
          borderTopRightRadius: '16px',
        }}
      >
        <CameraAlt />
        <Typography fontSize="16px" fontWeight={600} textTransform="uppercase">
          {activeEventType === 'LytxPreEvent'
            ? 'Lytx Pre Event'
            : activeEventType}{' '}
          EVENT DETAILS
        </Typography>

        <Typography
          fontSize="16px"
          fontWeight={600}
          ml="auto"
          color={isLeadTimeExceedingThirtyMinutes ? 'error' : 'inherit'}
        >
          {formatLeadTime(leadTime)}
        </Typography>
      </Stack>
      <div
        style={{
          display: 'flex',
          padding: '16px 40px',
          justifyContent: 'space-between',
        }}
      >
        <div>
          <Typography fontSize="12px" marginBottom="12px">
            Organisation: {eventDetailsData?.organisation ?? organisation}
          </Typography>
          <Typography fontSize="12px">
            Group: {eventDetailsData?.organisationGroup ?? 'Unknown'}
          </Typography>
        </div>
        <div>
          <Typography fontSize="12px" marginBottom="12px">
            Event Trigger: {eventDetailsData?.eventTrigger ?? eventTrigger}
          </Typography>
          <Typography fontSize="12px">Event: {event}</Typography>
        </div>
        <div>
          <Typography fontSize="12px" marginBottom="12px">
            Speed: {speed}
          </Typography>
          <Typography fontSize="12px">
            Vehicle: {eventDetailsData?.vehicle ?? vehicle}
          </Typography>
        </div>
        <div>
          <Typography fontSize="12px" marginBottom="12px">
            Serial: {serial}
          </Typography>
          <Typography fontSize="12px">Driver: {driver}</Typography>
        </div>
        <div>
          <Typography fontSize="12px" marginBottom="12px">
            Created: {creationDate}
          </Typography>
          <Typography fontSize="12px">Recorded: {recordedDate}</Typography>
        </div>
      </div>
    </Stack>
  );
}

export function EventDetails({
  eventDetailsData,
  inlineEventData,
  mimicObject,
}: {
  eventDetailsData?: EventDetailsData;
  inlineEventData?: EarlyWarningEventDetailsResponse;
  mimicObject?: EventDetailMimicData;
}) {
  const {webSocket} = useEventReviewContext();
  if (
    eventDetailsData == null &&
    mimicObject == null &&
    webSocket.activeEventType === 'Lytx'
  ) {
    return <EventDetailsShimmer />;
  }

  if (eventDetailsData != null || webSocket.activeEventType !== 'Lytx') {
    return <EventDetailsWithHook eventDetailsData={inlineEventData} />;
  }

  if (mimicObject != null) {
    return <EventDetailsMimic mimicObject={mimicObject} />;
  }
}
