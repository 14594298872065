import {useLocation} from 'react-router-dom';

import DeleteIcon from '@mui/icons-material/Delete';
import Send from '@mui/icons-material/Send';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import {
  type ContactDump,
  type EarlyWarningEventComment,
  type ContactOrganisationGroupExternalContact,
  type BehaviourOutcomeCalculate,
} from '@onroadvantage/onroadvantage-api';

import {NotificationContactRow} from './NotificationContactRow';
import {VantageSelectField} from '../../../../common/components/form/VantageSelectField';
import {VantageTextField} from '../../../../common/components/form/VantageTextField';
import {
  type DeletableComment,
  useNotificationContacts,
} from '../../../hooks/useNotificationContacts';

export function NotificationContacts({
  contacts,
  eventDetailsComments,
  outcomes,
}: {
  contacts?: ContactOrganisationGroupExternalContact[] | ContactDump[];
  eventDetailsComments?: EarlyWarningEventComment[];
  outcomes?: BehaviourOutcomeCalculate[];
}) {
  const {
    comment,
    setComment,
    handleCommentSubmit,
    commentsList,
    handleCallTriggered,
    handleCommentDelete,
    handleReasonCode,
    REASON_CODE_UNANSWERED,
    REASON_CODE_ANSWERED,
    callTriggeredContactNames,
    extractNotificationTypes,
  } = useNotificationContacts({eventDetailsComments});
  const {pathname} = useLocation();
  const outcomeIds =
    outcomes?.map((outcome) => outcome.id).filter((id) => id != null) ?? [];

  return (
    <Stack
      sx={{
        backgroundColor: '#EFEFEF',
        borderRadius: '16px',
        padding: '12px 12px 0 12px',
        height: '100%',
        marginTop: '16px',
      }}
    >
      <TableContainer>
        <Table aria-label="notifications table">
          <TableHead>
            <TableRow>
              <TableCell colSpan={4}>
                <Typography variant="h5" component="h2">
                  Notifications
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography variant="subtitle2">Name</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle2">Phone</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle2">Email</Typography>
              </TableCell>
              <TableCell align="right">
                <Typography variant="subtitle2">Actions</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {contacts != null &&
              contacts?.length > 0 &&
              contacts.map((contact) => {
                if ('id' in contact) {
                  return (
                    <NotificationContactRow
                      key={contact.id}
                      {...contact}
                      onCallClicked={handleCallTriggered}
                    />
                  );
                }
                if ('behaviourOutcomes' in contact) {
                  const enabledNotificationTypes = extractNotificationTypes(
                    contact.behaviourOutcomes ?? [],
                    outcomeIds,
                  );

                  return (
                    <NotificationContactRow
                      key={contact?.contact?.id}
                      {...contact.contact}
                      onCallClicked={handleCallTriggered}
                      enabledNotificationTypes={enabledNotificationTypes}
                    />
                  );
                }
                return null;
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <Divider />
      <Box p={2}>
        <Typography variant="h6" gutterBottom>
          Comments
        </Typography>
        <Stack direction="row">
          <List sx={{width: '50%'}}>
            {commentsList.map((notificationComment) => (
              <ListItem
                key={notificationComment.text}
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  minHeight: '68px',
                  width: '100%',
                  backgroundColor: '#FFFFFF',
                  borderRadius: '16px',
                  marginBottom: '12px',
                }}
              >
                <ListItemText
                  primary={notificationComment.text}
                  secondary={notificationComment?.commentTime?.toLocaleString()}
                  secondaryTypographyProps={{
                    position: 'absolute',
                    right: '8px',
                    top: '8px',
                    fontSize: '12px',
                  }}
                />
                <IconButton
                  aria-label="delete"
                  sx={{
                    height: '24px',
                    width: '24px',
                    position: 'absolute',
                    right: '4px',
                    bottom: '8px',
                  }}
                  onClick={() => {
                    handleCommentDelete(
                      notificationComment as DeletableComment,
                    );
                  }}
                >
                  <DeleteIcon sx={{height: '20px', width: '20px'}} />
                </IconButton>
              </ListItem>
            ))}
          </List>

          {callTriggeredContactNames.length > 0 && (
            <Stack direction="column" width="50%">
              {callTriggeredContactNames.map((callTriggeredContactName) => {
                const answeredLabel = REASON_CODE_ANSWERED(
                  callTriggeredContactName,
                );
                const unansweredLabel = REASON_CODE_UNANSWERED(
                  callTriggeredContactName,
                );
                return (
                  <Box p={2} pt="12px" key={callTriggeredContactName}>
                    <VantageSelectField
                      name="reasonCode"
                      options={[answeredLabel, unansweredLabel]}
                      onChange={(e) => {
                        handleReasonCode(e);
                      }}
                      label={`What was the call outcome for ${callTriggeredContactName}?`}
                    />
                  </Box>
                );
              })}
            </Stack>
          )}
        </Stack>
        <Stack alignItems="flex-end">
          <VantageTextField
            name="message"
            placeholder="Enter your comments here"
            value={comment}
            onChange={(e) => {
              setComment(e.target.value);
            }}
            multiline
            sx={{marginBottom: '12px'}}
            disabled={pathname.includes('videoEvent')}
          />
          <Button
            onClick={handleCommentSubmit}
            variant="contained"
            color="primary"
            endIcon={<Send />}
            disabled={comment.trim() === '' || pathname.includes('videoEvent')}
            sx={{width: 'fit-content'}}
          >
            Add Comment
          </Button>
        </Stack>
      </Box>
    </Stack>
  );
}
