import {useMemo} from 'react';

import {
  formatDateTime,
  formatDriverName,
  formatSpeed,
} from '../../videoEvents/utils/VideoEventDetailUtils';
import {useEventReviewContext} from '../contexts/EventReviewContext';
import {calculateLeadTime} from '../utils/feedbackUtils';
import {type WebSocketHook} from '../WebsocketTypes';

interface EventData {
  id?: string | number;
  customerEventId?: string;
  driverFirstName?: string;
  driverLastName?: string;
  creationDate?: string;
  recordDateUTC?: string;
  speed?: number;
  erSerialNumber?: string;
  time?: string;
  organisation?: string;
  vehicle?: string;
  imei?: number;
  eventTrigger?: string;
}

const useActiveEventData = (webSocket: WebSocketHook) => {
  return useMemo(() => {
    const activeEvent: EventData = webSocket.events[0]?.data.data ?? {};
    const {activeEventType} = webSocket;

    const fieldMap: Record<
      string,
      {
        date: string | undefined;
        serial: string | undefined;
        organisation: string | undefined;
        vehicle: string | undefined;
        eventTrigger: string | undefined;
      }
    > = {
      SurfSight: {
        date: activeEvent.time,
        serial: String(activeEvent?.imei),
        organisation: activeEvent?.organisation ?? 'SurfSight',
        vehicle: activeEvent?.vehicle ?? 'Unknown',
        eventTrigger: activeEvent?.eventTrigger,
      },
      LytxPreEvent: {
        date: activeEvent.recordDateUTC,
        serial: activeEvent.erSerialNumber,
        organisation: activeEvent?.organisation ?? 'Lytx',
        vehicle: activeEvent?.vehicle ?? 'Unknown',
        eventTrigger: 'Unknown',
      },
      default: {
        date: activeEvent.creationDate,
        serial: activeEvent.erSerialNumber,
        organisation: undefined,
        vehicle: undefined,
        eventTrigger: undefined,
      },
    };

    const resolvedFields =
      fieldMap[activeEventType as string] ?? fieldMap.default;

    const event = activeEvent?.customerEventId ?? 'Unknown';
    const driver = formatDriverName(
      activeEvent.driverFirstName,
      activeEvent.driverLastName,
    );
    const creationDate = formatDateTime(
      resolvedFields.date ?? activeEvent.creationDate,
    );
    const recordedDate = formatDateTime(resolvedFields.date);
    const speed = formatSpeed(activeEvent.speed);
    const serial = resolvedFields.serial ?? 'Unknown';
    const leadTime = calculateLeadTime(resolvedFields.date);

    return {
      event,
      driver,
      creationDate,
      recordedDate,
      speed,
      serial,
      leadTime,
      ...(resolvedFields.organisation != null && {
        organisation: resolvedFields.organisation,
      }),
      ...(resolvedFields.vehicle != null && {vehicle: resolvedFields.vehicle}),
      ...(resolvedFields.eventTrigger != null && {
        eventTrigger: resolvedFields.eventTrigger,
      }),
    };
  }, [webSocket]);
};

export const useEventDetails = () => {
  const {webSocket} = useEventReviewContext();

  const activeEventData = useActiveEventData(webSocket);

  return {
    ...activeEventData,
  };
};
