import {useCallback, useEffect} from 'react';

import {useEventReviewContext} from '../contexts/EventReviewContext';
import {type TableLookup} from '../pages/EventFeedback';

export const useCheckboxSelectOnKey = ({
  lookupTable,
}: {
  lookupTable: TableLookup;
}) => {
  const {
    keyBuffer,
    setKeyBuffer,
    handleLateClip,
    handleNoIssue,
    handleFaultyClip,
  } = useEventReviewContext();

  const handleKeyBuffer = useCallback(() => {
    if (keyBuffer !== '') {
      if (keyBuffer === 'lc') {
        handleLateClip();
        setKeyBuffer('');
      }
      if (keyBuffer === 'n') {
        handleNoIssue();
      }
      if (keyBuffer === 'ce') {
        handleFaultyClip();
      }

      const checkbox = lookupTable[keyBuffer];
      if (checkbox != null) {
        const element: HTMLInputElement | null = document.querySelector(
          `input[name="__lookupKey: ${checkbox.shortcut}__${checkbox.name}"]`,
        );
        if (element != null) {
          element.click();
        }
      }
      setTimeout(() => {
        setKeyBuffer('');
      }, 555);
    }
  }, [
    keyBuffer,
    lookupTable,
    handleLateClip,
    setKeyBuffer,
    handleNoIssue,
    handleFaultyClip,
  ]);

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.code === 'Space') {
        return;
      }
      const key = event.key.toLowerCase();
      setKeyBuffer((prev) => prev + key);
    };

    const bufferTimeout = setTimeout(handleKeyBuffer, 300);

    window.addEventListener('keydown', handleKeyPress);

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
      clearTimeout(bufferTimeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyBuffer, handleKeyBuffer]);
};
