import type {MenuItem} from './menuItems';
import {routes} from '../../../../../routes';
import {eventTypeModels} from '../../../types/EventTypeModel';

export const masterDataMenuItems: MenuItem[] = [
  {
    level: 2,
    label: 'Audience Details',
    children: [
      {
        level: 3,
        label: 'Audience',
        pathname: routes.audience.list,
        permission: 'audience:list',
      },
      {
        level: 3,
        label: 'Audience Groups',
        pathname: routes.audienceGroup.list,
        permission: 'audienceGroup:list',
      },
    ],
  },
  {
    level: 2,
    label: 'Contract Details',
    children: [
      {
        level: 3,
        label: 'Contracts',
        pathname: routes.contract.list,
        permission: 'contract:list',
      },
      {
        level: 3,
        label: 'Contract Groups',
        pathname: routes.contractGroup.list,
        permission: 'contractGroup:list',
      },
    ],
  },
  {
    level: 2,
    label: 'Driver Details',
    children: [
      {
        level: 3,
        label: 'Drivers',
        pathname: routes.driver.list,
        permission: 'driver:list',
      },
      {
        level: 3,
        label: 'Driver Groups',
        pathname: routes.driverGroup.list,
        permission: 'driverGroup:list',
      },
    ],
  },
  {
    level: 2,
    label: 'Event Details',
    children: [
      {
        level: 3,
        label: 'Bulk Closing',
        pathname: routes.event.bulkClosing,
        permission: 'criticalEvent BulkAction:edit',
      },
      {
        level: 3,
        label: 'Critical Event Types',
        pathname: routes.addQueryParams(routes.eventType.list, {
          model: eventTypeModels.CriticalEventType,
        }),
        permission: 'criticalEventType:list',
      },
      {
        level: 3,
        label: 'Contract Critical Event Type Configs',
        pathname: routes.addQueryParams(routes.contractEventTypeConfig.list, {
          model: eventTypeModels.CriticalEventType,
        }),
        permission: 'criticalEventType:list',
      },
      {
        level: 3,
        label: 'Operational Event Types',
        pathname: routes.addQueryParams(routes.eventType.list, {
          model: eventTypeModels.OperationalEventType,
        }),
        permission: 'operationalEventType:list',
      },
      {
        level: 3,
        label: 'Contract Operational Event Type Configs',
        pathname: routes.addQueryParams(routes.contractEventTypeConfig.list, {
          model: eventTypeModels.OperationalEventType,
        }),
        permission: 'operationalEventType:list',
      },
      {
        level: 3,
        label: 'Video Events',
        pathname: routes.videoEvents.list,
      },
    ],
  },
  {
    level: 2,
    label: 'Early Warning Details',
    permission: 'triggerConfig:view',
    children: [
      {
        level: 3,
        label: 'Trigger Config',
        pathname: routes.earlyWarning.triggerInclusionConfig,
        permission: 'triggerConfig:view',
      },
      {
        level: 3,
        label: 'Hindsight Config',
        pathname: routes.earlyWarning.hindsightConfig,
        permission: 'hindsightConfig:view',
      },
    ],
  },
  {
    level: 2,
    label: 'Product Details',
    children: [
      {
        level: 3,
        label: 'Products',
        pathname: routes.product.list,
        permission: 'product:list',
      },
      {
        level: 3,
        label: 'Product Groups',
        pathname: routes.productGroup.list,
        permission: 'productGroup:list',
      },
    ],
  },
  {
    level: 2,
    label: 'Site Details',
    children: [
      {
        level: 3,
        label: 'Sites',
        pathname: routes.site.list,
        permission: 'site:list',
      },
      {
        level: 3,
        label: 'Site Types',
        pathname: routes.siteType.list,
        permission: 'siteType:list',
      },
    ],
  },
  {
    level: 2,
    label: 'Vehicle Details',
    children: [
      {
        level: 3,
        label: 'Vehicles',
        pathname: routes.vehicle.list,
        permission: 'vehicle:list',
      },
      {
        level: 3,
        label: 'Vehicle Groups',
        pathname: routes.vehicleGroup.list,
        permission: 'vehicleGroup:list',
      },
      {
        level: 3,
        label: 'Vehicle Types',
        pathname: routes.vehicleType.list,
        permission: 'vehicleType:list',
      },
      {
        level: 3,
        label: 'Speed Tables',
        pathname: routes.speedTable.list,
        permission: 'speedTable:list',
      },
    ],
  },
  {
    level: 2,
    label: 'Webhook Details',
    children: [
      {
        level: 3,
        label: 'Webhook Auth',
        pathname: routes.webhookAuth.list,
        permission: 'webhookAuth:list',
      },
      {
        level: 3,
        label: 'Webhooks',
        pathname: routes.webhook.list,
        permission: 'webhook:list',
      },
    ],
  },
  {
    level: 2,
    label: 'Other Details',
    children: [
      {
        level: 3,
        label: 'Customers',
        pathname: routes.customer.list,
        permission: 'customer:list',
      },
      {
        level: 3,
        label: 'Planning Skills',
        pathname: routes.planningSkill.list,
        permission: 'planningSkill:list',
      },
      {
        level: 3,
        label: 'Transporters',
        pathname: routes.transporter.list,
        permission: 'transporter:list',
      },
      {
        level: 3,
        label: 'Workflow Configs',
        pathname: routes.workflowConfig.list,
        permission: 'workflowConfig:list',
      },
      {
        level: 3,
        label: 'Group External Organisations',
        pathname: routes.groupExternal.base,
      },
    ],
  },
  {
    level: 2,
    label: 'Upload',
    pathname: routes.upload,
    permission: 'uploadData:view',
  },
];
