import FullscreenIcon from '@mui/icons-material/Fullscreen';
import Pause from '@mui/icons-material/Pause';
import PlayArrow from '@mui/icons-material/PlayArrow';
import IconButton from '@mui/material/IconButton';
import Slider from '@mui/material/Slider';
import Stack from '@mui/material/Stack';
import {styled} from '@mui/material/styles';

import {TimelineCounter} from './TimelineCounter';

interface EventReviewVideoTimelineProps {
  duration: number;
  currentTime: number;
  playing: boolean;
  togglePlay: () => void;
  forwardDisabled?: boolean;
  handleSliderChange: (event: Event, newValue: number | number[]) => void;
  handleFullscreen: () => Promise<void>;
}

export function EventReviewVideoTimeline({
  duration,
  currentTime,
  playing,
  togglePlay,
  forwardDisabled = false,
  handleSliderChange,
  handleFullscreen,
}: EventReviewVideoTimelineProps) {
  return (
    <div
      style={{
        backgroundColor: '#EFEFEF',
        marginTop: '24px',
        borderRadius: '16px',
      }}
    >
      <div
        style={{
          display: 'flex',
          width: '100%',
          alignItems: 'center',
          padding: '32px 8px 0 8px',
        }}
      >
        <IconButton
          onClick={togglePlay}
          sx={{marginRight: '12px', marginLeft: '0px'}}
        >
          {playing ? (
            <Pause sx={{color: '#4c4c4c'}} />
          ) : (
            <PlayArrow sx={{color: '#4c4c4c'}} />
          )}
        </IconButton>
        <VideoTimeline
          disabled={forwardDisabled}
          value={currentTime}
          min={0}
          max={duration}
          onChange={handleSliderChange}
        />
        <IconButton onClick={handleFullscreen}>
          <FullscreenIcon sx={{color: '#4c4c4c'}} />
        </IconButton>
      </div>
      <Stack
        width="100%"
        direction="row"
        justifyContent="space-between"
        px="20px"
        pb="12px"
      >
        <TimelineCounter currentTime={currentTime} duration={duration} />
      </Stack>
    </div>
  );
}

const VideoTimeline = styled(Slider)(() => ({
  color: '#4c4c4c',
  marginRight: '12px',
  '&.Mui-disabled': {
    color: '#4c4c4c',
  },
  '& .MuiSlider-rail': {
    opacity: 1,
    height: '8px',
  },
  '& .MuiSlider-thumb': {
    height: '16px',
    width: '16px',
    color: '#808080',

    '&.Mui-active': {
      boxShadow: 'none',
    },
    '&:hover': {
      boxShadow: 'none',
    },
  },
}));
